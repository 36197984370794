import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "JAWS DAYS 2019 にメドレーがランチサポーターとして協賛しました",
  "date": "2019-03-01T03:00:49.000Z",
  "slug": "entry/2019/03/01/120049",
  "tags": ["medley"],
  "hero": "./2019_03_01.png",
  "heroAlt": "JQWS DAYS 2019"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`こんにちは。開発本部エンジニアの平木です。`}</p>
    <p>{`去る 2019/02/23(土)に開催された JAWS-UG（AWS User Group – Japan）主催の全国規模での交流イベントである`}<a parentName="p" {...{
        "href": "https://jawsdays2019.jaws-ug.jp/"
      }}>{`JAWS DAYS 2019`}</a>{`にメドレーが`}<strong parentName="p">{`‌ ランチサポーター`}</strong>{`として協賛させていただきましたので、当日の様子などをお伝えしていきます。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20190228/20190228122657.jpg",
      "alt": "20190228122657.jpg"
    }}></img>
    <h1>{`会場の様子`}</h1>
    <p>{`自分はこちらのイベントに初参加だったのですが、来場者数がかなり多い!ということに圧倒されました。会場の`}<a parentName="p" {...{
        "href": "https://messe.toc.co.jp/"
      }}>{`TOC 五反田メッセ`}</a>{`はイベント会場としては大分広い方でしたが、セッションの合間などでは移動するのも中々大変な位でした。日本全国から AWS ユーザが集まるイベントの規模の大きさが伺えました。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20190228/20190228122724.jpg",
      "alt": "20190228122724.jpg"
    }}></img>
    <p><a parentName="p" {...{
        "href": "https://jawsdays2019.jaws-ug.jp/archives/2597/"
      }}>{`公式発表`}</a>{`で 1,900 人の来場だったということで、歴史があるユーザグループの凄さを実感しました。このため、大体のセッションも満席か立ち見が出ているのが印象的でした。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20190228/20190228122752.jpg",
      "alt": "20190228122752.jpg"
    }}></img>
    <p>{`セッションもバラエティに富んだ`}<a parentName="p" {...{
        "href": "https://jawsdays2019.jaws-ug.jp/timetable/"
      }}>{`ラインナップ`}</a>{`で見たいものが若干カブってしまっていた位で、大変有意義なものでした。`}</p>
    <p>{`またこちらは、アマゾンウェブサービスジャパン株式会社さんが後援しているイベントということで、`}<a parentName="p" {...{
        "href": "https://twitter.com/jawsdays"
      }}>{`JAWS-UG 公式 Twitter`}</a>{`に負けず劣らず、`}<a parentName="p" {...{
        "href": "https://twitter.com/awscloud_jp"
      }}>{`アマゾンウェブサービス公式 Twitter`}</a>{`でイベントの様子を Tweet していたのは、さすがだなと思わされました。`}</p>
    <h1>{`メドレーのスポンサー活動`}</h1>
    <p>{`さて、そんな活気に包まれていた JAWS DAYS 2019 での弊社のスポンサーとしての取り組みについてですが、今回は`}<strong parentName="p">{`‌ ランチサポーター`}</strong>{`として`}<a parentName="p" {...{
        "href": "https://jawsdays2019.jaws-ug.jp/supporter/"
      }}>{`協賛させていただきました`}</a>{`。`}</p>
    <h2>{`企業サポーターブース`}</h2>
    <p>{`まずは企業サポーターブースで弊社のエンジニア・デザイナー採用パンフレットと、ノベルティとしてロゴ入りの絆創膏を設置させてもらいました!この絆創膏ですが、十分な数かと思っていたのですが、おかげさまで朝にセッティングしたところお昼までには無くなってしまいました。もっと持っていけば良かったと反省しています。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20190228/20190228122821.jpg",
      "alt": "20190228122821.jpg"
    }}></img>
    <p>{`パンフレットを置かせていただいていたのは、会場内で 2 番目くらいに大きい場所でしたがこちらも各サポーター企業さんのブースや同人誌即売コーナーなどで人が溢れんばかりでした。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20190228/20190228122855.jpg",
      "alt": "20190228122855.jpg"
    }}></img>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20190228/20190228122904.jpg",
      "alt": "20190228122904.jpg"
    }}></img>
    <h2>{`ランチセッション`}</h2>
    <p>{`そして、ランチサポーターとしてランチセッションで弊社の田中が`}<a parentName="p" {...{
        "href": "https://jawsdays2019.jaws-ug.jp/session/1000/"
      }}>{`AWS マネージドサービスをフル活用して医療系システムを構築・運用するためのノウハウ`}</a>{`と題したセッションでお話させていただきました。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20190228/20190228123004.jpg",
      "alt": "20190228123004.jpg"
    }}></img>
    <p>{`メドレーで運営しているサービスである CLINICS カルテを AWS を使って、国で定められたガイドラインにきちんと準拠しながら構築・運用するために今まで行なったことを中心に、AWS の各サービスをどのように使っているか?というお話をさせていただきました。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20190228/20190228123035.jpg",
      "alt": "20190228123035.jpg"
    }}></img>
    <p>{`詳細としては`}</p>
    <ul>
      <li parentName="ul">{`医療情報システムを構成する際に準拠しなければならない 3 省 3 ガイドラインの抜粋`}</li>
      <li parentName="ul">{`CLINICS カルテでガイドラインがシステム構成に影響を与えた部分`}
        <ul parentName="li">
          <li parentName="ul">{`日本国内法の適用が及ぶ場所へのシステムの設置`}</li>
          <li parentName="ul">{`ユーザである医療機関の使用するアプリ側で TLS クライアント認証を実施`}</li>
          <li parentName="ul">{`ユーザアカウント毎の権限管理と各種操作ログの管理`}</li>
        </ul>
      </li>
      <li parentName="ul">{`AWS 東京リージョンへの移行や、システム構成を AWS の各サービス(`}<a parentName="li" {...{
          "href": "https://aws.amazon.com/jp/ecs/features/"
        }}>{`ECS`}</a>{`や`}<a parentName="li" {...{
          "href": "https://aws.amazon.com/jp/fargate/"
        }}>{`fargate`}</a>{`、`}<a parentName="li" {...{
          "href": "https://docs.aws.amazon.com/ja_jp/elasticloadbalancing/latest/network/introduction.html"
        }}>{`NLB`}</a>{`など)を使いながら TLS クライアントに対応していった話
セキュリティを強固にするために AWS サービス群をどのように使用しているかの話`}
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://aws.amazon.com/jp/cloudtrail/"
            }}>{`AWS CloudTrail`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://docs.aws.amazon.com/ja_jp/vpc/latest/userguide/flow-logs.html"
            }}>{`Flow logs`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://aws.amazon.com/jp/guardduty/"
            }}>{`Amazon GuardDuty`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://aws.amazon.com/jp/config/"
            }}>{`AWS Config`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://aws.amazon.com/jp/systems-manager/#Session_Manager"
            }}>{`Session Manager`}</a></li>
        </ul>
      </li>
    </ul>
    <p>{`といった内容の発表でした。
来場していただいた方々の関心も高いようで、写真を撮りながら聞いていただいて大変ありがたかったです。セッションが終了したあとも参加していただいた方々に個別に話を聞きに来ていただいたりと、情報交換という意味でも大変有意義なセッションとなりました。`}</p>
    <figure {...{
      "className": "figure-image figure-image-fotolife",
      "title": "セッションが終わって一安心した田中"
    }}><img parentName="figure" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20190228/20190228123114.jpg",
        "alt": "20190228123114.jpg"
      }}></img><figcaption parentName="figure">{`セッションが終わって一安心した田中`}</figcaption></figure>
    <h1>{`最後に`}</h1>
    <p>{`イベントを運営していただいたスタッフの方々、そして参加者の皆さまありがとうございました!`}</p>
    <p>{`メドレーではこの他にもエンジニア・デザイナー向けのイベントのスポンサーを積極的にしております。スポンサーを募集しているイベント主催者の方などいらっしゃいましたら、お気軽に`}<a parentName="p" {...{
        "href": "https://www.medley.jp/contact/"
      }}>{`お問い合わせ`}</a>{`いただければと思います。`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      